import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/services/links/links"

export default function Immigration({ data }) {
  return (
    <Layout>
      <SEO title="Immigration" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Services"
      />
      <div className="width75">
        <h1 className="title">Immigration</h1>
        <Links type="about" />
        <div className="ribbon-top"></div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "tower-bridge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
